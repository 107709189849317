
<template>
      <b-modal 
        id="addSubSpecialityModal" 
        ref="modal" 
        title="Add Sub Specialities"
        @show="resetModal"
        @hidden="resetModal"
        hide-footer
       >
      <form ref="form" @submit.prevent="add_sub_specialities">
        <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="sub_specialities_title"
            :placeholder="modal_placeholder"
            :class="{ 'is-invalid': isValid && $v.sub_specialities_title.$error }"
          ></b-form-input>
        </b-form-group>
        <b-button variant="primary" v-on:click="add_sub_specialities">Submit</b-button>
      </form>
    </b-modal>
</template>

<script>
import { subSpecialityMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg : "",
      error:false,
      isValid: false,
      sub_specialities_title:'',
    };
  },
  props:{
    modal_placeholder:{
      type:String,
    }
  },
  validations: {
    sub_specialities_title: {
      required
    },
  },
  methods:{
    ...subSpecialityMethods,
    resetModal(){
      this.sub_specialities_title='';
      this.msg='';
      this.error=false;
      this.isValid = false;
    },

    add_sub_specialities() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.addSubSpeciality({speciality_id:this.$route.params.speciality_id,title:this.sub_specialities_title}).then(res => {
        this.$refs['modal'].hide();
        if (res.status == 200) {
          this.msg = res.message ? res.message : "Sub Speciality added successfully"
          this.$swal({
              icon : 'success',
              toast : true,
              text: this.msg,
            showCloseButton: false,
              showConfirmButton: false,
              timer: 3000
          }).then(
          this.getAllSubSpecialities({id:this.$route.params.speciality_id}),
          );
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        });
        }
      });
    }
  }
};
</script>
