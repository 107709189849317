
<template>
      <b-modal
        id="editSubSpecialityModal"
        ref="modal" 
        title="Edit Sub Specialities"
        @show="resetModal"
        @hidden="resetModal"
        hide-footer
       >
      <form ref="form" @submit.prevent="edit_sub_specialities">
        <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="sub_specialities_title"
            :class="{ 'is-invalid': isValid && $v.sub_specialities_title.$error }"
          ></b-form-input>
        </b-form-group>
      <b-button variant="primary" v-on:click="edit_sub_specialities">Update</b-button>
      </form>
    </b-modal>
</template>

<script>
import { subSpecialityMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg:'',
      error: false,
      isValid: false,
      sub_specialities_title:'',
      sub_specialities_id:0,
    };
  },
  validations: {
    sub_specialities_title: {
      required
    },
  },
  props: {
    selected_title: {
      type: String,
    },
    selected_id:{
      type: Number,
    },
  },
  methods: {
    ...subSpecialityMethods,
    // reset all fields of modal on click
    resetModal(){
    this.sub_specialities_title=this.selected_title;
    this.sub_specialities_id=this.selected_id;
    this.msg='';
    this.error=false;
    this.isValid = false;    
    },
    // hit api to update service
    edit_sub_specialities() { 
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }      
     this.updateSubSpeciality({id:this.sub_specialities_id,title:this.sub_specialities_title,speciality_id:this.$route.params.speciality_id}).then(res => {
       this.$refs['modal'].hide();
      if (res.status == 200) {
        this.msg = res.message ? res.message : "Sub Speciality updated successfully"
         this.$swal({
          icon : 'success',
          text: this.msg,
          toast:true,
          showCloseButton: false,
             showConfirmButton: false,
             timer: 3000
         }).then(
        this.getAllSubSpecialities({id:this.$route.params.speciality_id}),
         );
      }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        });
        }
      })
    },
  },
  mounted(){

  }
};
</script>
